//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
import defaultImg from '@/assets/shopinspect/note.png'
export default {
  name: "detail",
  data() {
    return {
      defaultImg:defaultImg,
      fileList: [],
      isOpen: false, //是否需要问题反馈
      isEdit: true, //是否在编辑
      answers: [], //多选答案id
      minDate: new Date(this.$moment().subtract(30, "days")),
      showCalendar: false,
      form: {
        id: undefined,
        content: "", //答案
        finishDate: "",
        qdepartment: "",
        qdescription: "",
        imgList: [],
        questionId: "",
        orderId: "",
        answer:''
      },
      questionId: "",
      orderId: "",
      isComplete: false, //巡检表是否已完成
      question: {
        questionItems: [],
      },
      paperItemsAll: [], //全部题目
      paperItemsAllNoAnswered: [], //未答题目
      imageHost: "https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/",
    };
  },
  created() {},
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave',to);
    to.query.noteId=this.question.noteId
    to.query.title=this.question.noteTitle
    next();
},
  methods: {
    deleteAnswerImg(item) {
      console.log(item);
      if (item.id) {
        this.axios
          .get(
            "/" +
              this.$ajaxPrefix.consumer +
              "/store/patrol/answer/delimg?id=" +
              item.id
          )
          .then((res) => {
            console.log(res);
            if (res.data && res.data.data && res.data.code == 0) {
              console.log("noteimg/create", res.data.data);
              Toast("删除成功");
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          });
      }
    },
    oversizeFile(){
       this.$q.notify({
          color: "red-5",
          textColor: "white",
          icon: "warning",
          position: "center",
          message: '上传图片大小不可大于10m！',
        });
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file);
      let formData = new FormData();
      formData.append("file", file.file);
      file.status = "uploading";
      file.message = "上传中...";
      this.axios
        .post("/" + this.$ajaxPrefix.consumer + "/oss/uploadImg", formData)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data && res.data.code == 0) {
            console.log("uploadImg", res.data.data);
            file.url = res.data.data.urlPath;
            file.objectName = res.data.data.objectName;
            file.status = "";
            file.message = "";
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        });
    },
    //获取巡检表信息与试卷id
    initLoad() {
      if (this.orderId > 0) {
        this.axios
          .get(
            "/" +
              this.$ajaxPrefix.consumer +
              "/store/patrol/order/patrol/getOrder?id=" +
              this.orderId
          )
          .then((res) => {
            if (res.data && res.data.code == 0) {
              console.log("initLoad", res.data);
              this.isComplete = res.data.data.isComplete;
              this.getPaper(res.data.data.paperId);
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          });
      }
    },
    //获取试卷
    getPaper(id) {
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            "/store/patrol/paper/get?paperId=" +
            id
        )
        .then((res) => {
          if (res.data && res.data.code == 0) {
            console.log("getPaper", res.data);
            res.data.data.map((item,index) => {
              item.index = index + 1
              if (item.id == this.questionId) {
                this.question = item;
                window.document.title = item.noteTitle;
              }
            });
            this.paperItemsAll = res.data.data;
            this.getAnswers();
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        });
    },
    //获取答案列表
    getAnswers() {
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            "/store/patrol/answer/getPaperQuestion?orderId=" +
            this.orderId
        )
        .then((res) => {
          console.log("getPaperQuestion", res.data);
          if (res.data && res.data.code == 0) {
            if (res.data.data && res.data.data.answerList) {
              res.data.data.answerList.map((answer) => {
                //所有问题答题情况
                this.paperItemsAll.map((paperItem) => {
                  if (paperItem.id == answer.questionId) {
                    paperItem.answer = answer.content;
                  }
                });

                if (this.question.qtypeId == 1) {
                  //当前答案展示
                  this.question.questionItems.map((questionItem, index) => {
                    //默认答案
                    if (questionItem.defaultChecked == 1) {
                      this.$set(
                        this.form,
                        "content",
                        parseInt(questionItem.id)
                      );
                      this.$refs.checkboxes[index].toggle();
                      // this.isEdit = false;
                    }
                    if (
                      answer.questionId == this.questionId &&
                      answer.content == questionItem.id
                    ) {
                      answer.content = parseInt(answer.content);
                      this.$set(this, "form", answer);
                      this.$refs.checkboxes[index].toggle();
                      this.isOpen = !!(questionItem.isOpen == 1);
                      // this.isEdit = false;
                      if (this.form.imgList) {
                        this.fileList = [];
                        this.form.imgList.map((v) => {
                          this.fileList.push({
                            url: this.imageHost + v.content,
                            objectName: v.content,
                            id: v.id,
                          });
                        });
                      }
                    }
                  });
                } else if (this.question.qtypeId == 10) {
                  if (answer.questionId == this.questionId) {
                    this.$set(this, "form", answer);
                    // this.isEdit = false;
                  }
                }
              });
              //未答问题
              this.paperItemsAllNoAnswered = this.paperItemsAll.filter(
                (item) => {
                  return !item.answer;
                }
              );
            }
          } else {
            this.$q.notify({
              color: "red-5",
              textColor: "white",
              icon: "warning",
              position: "center",
              message: res.data.msg,
            });
          }
        });
    },
    //提交答案
    onSubmit() {
      console.log(this.form);
      if (!this.form.content) {
        this.$toast({ message: "请先回答问题", duration: 3000 });
        return;
      }
      let postData = JSON.parse(JSON.stringify(this.form));
      if (this.question.qtypeId == 10) {
        this.axios
          .post(
            "/" +
              this.$ajaxPrefix.consumer +
              "/store/patrol/answer/answerBlank",
            postData
          )
          .then((res) => {
            console.log("answerSingleSelect", res.data);
            if (res.data && res.data.code == 0) {
              // Toast("提交成功");
              this.toggleQuestion(1);
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          });
      } else {
        if (this.fileList.length > 0) {
          postData.imgList = [];
          this.fileList.map((v) => {
            if (!v.id) {
              postData.imgList.push({
                answerId: this.form.id || undefined,
                content: v.objectName,
                orderId: this.orderId,
                questionId: this.questionId,
              });
            }
          });
        }
        this.axios
          .post(
            "/" +
              this.$ajaxPrefix.consumer +
              "/store/patrol/answer/answerSingleSelect",
            postData
          )
          .then((res) => {
            console.log("answerSingleSelect", res.data);
            if (res.data && res.data.code == 0) {
              // Toast("提交成功");
              this.toggleQuestion(1);
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          });
      }
    },
    toggle(index) {
      if (this.isEdit) {
        this.$refs.checkboxes[index].toggle();
        this.form.answer = this.question.questionItems[index].id;
        this.isOpen = !!(this.question.questionItems[index].isOpen == 1);
      }
    },
    onConfirm(date) {
      this.form.finishDate = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      this.showCalendar = false;
    },
    toggleQuestion(num){
       let currentIndex = 0;
       this.paperItemsAll.map((item,index) =>{
         if(item.id == this.questionId){
           currentIndex = index
         }
       })
       if(currentIndex == this.paperItemsAll.length - 1 && num > 0){
         this.$router.go(-2)
        // this.$dialog.confirm({
        //   message: `确认完成巡检表, 完成后不可修改是否继续?`,
        // }).then(() => {
        //   let postData = {
        //     id:this.orderId,
        //     isComplete:1
        //   };
        //   this.axios
        //   .post(
        //     "/" + this.$ajaxPrefix.consumer +
        //     "/store/patrol/order/edit",
        //     postData
        //   )
        //   .then((res) => {
        //     if (res.data && res.data.code == 0) {
        //       Toast("操作成功");
        //       this.isComplete = 1
        //       this.$router.go(-2)
        //     } else {
        //       this.$q.notify({
        //         color: "red-5",
        //         textColor: "white",
        //         icon: "warning",
        //         position: "center",
        //         message: res.data.msg,
        //       });
        //     }
        //   })
        // }).catch(() => {

        // });
      } else {
        this.$router.replace({
          path: "/shopinspect/detail",
          query: {
            questionId:  this.paperItemsAll[currentIndex + num].id,
            orderId: this.orderId,
          },
        });
      }
    },
    goNext() {
      if(this.paperItemsAllNoAnswered.length == 0){
        this.$dialog.confirm({
          message: `确认完成巡检表, 完成后不可修改是否继续?`,
        }).then(() => {
          let postData = {
            id:this.orderId,
            isComplete:1
          };
          this.axios
          .post(
            "/" + this.$ajaxPrefix.consumer +
            "/store/patrol/order/edit",
            postData
          )
          .then((res) => {
            if (res.data && res.data.code == 0) {
              Toast("操作成功");
              this.isComplete = 1
            } else {
              this.$q.notify({
                color: "red-5",
                textColor: "white",
                icon: "warning",
                position: "center",
                message: res.data.msg,
              });
            }
          })
        }).catch(() => {

        });
      }else{
      //下一题未答题id
      let nextQid = this.paperItemsAllNoAnswered[0].id;
      let nextItems = this.paperItemsAllNoAnswered.filter((item) => {
        return item.id < this.questionId;
      });
      console.log(nextQid, nextItems.length);
      if (nextItems.length > 0) {
        nextQid = nextItems[0].id;
      }
      if (nextQid == this.questionId) {
        Toast("当前题目是最后一题未答题了");
        return;
      } else {
        console.log(123);
        if (this.isEdit) {
          this.$dialog
            .confirm({
              message: `当前题目未提交，修改内容将丢失，确认前往下一题?`,
            })
            .then(() => {
              console.log("nextQid", nextQid);
              this.$router.replace({
                path: "/shopinspect/detail",
                query: {
                  questionId: nextQid,
                  orderId: this.orderId,
                },
              });
            })
            .catch(() => {});
        } else {
          console.log("nextQid", nextQid);
          this.$router.replace({
            path: "/shopinspect/detail",
            query: {
              questionId: nextQid,
              orderId: this.orderId,
            },
          });
        }
      }
      }
    }
  },
  activated() {
    this.questionId = this.$route.query.questionId
      ? this.$route.query.questionId
      : 0;
    this.orderId = this.$route.query.orderId ? this.$route.query.orderId : 0;
    this.form.questionId = this.questionId;
    this.form.orderId = this.orderId;
    this.initLoad();
  },
};
